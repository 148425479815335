import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const namespaces = [
  'common',
  'current-documentation',
  'home',
  'new-members',
  'search-box',
  'search',
  'terms',
  'company-info',
  'error',
  'upcoming-model',
  'privacy',
  'cookies',
  'banner',
  'about',
  'mission-vision',
  'jobs',
  'my-atrias',
  'file-download',
  'faq',
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next);

export const initI18n = (caches) => i18n.init({
  backend: {
  // Use the build number for cache busting
  loadPath: '/locales/{{lng}}/{{ns}}.json?v={{cacheBust}}',
    queryStringParams: { cacheBust: Math.floor(Date.now() / (4 * 60 * 60 * 1000)) }, /* Change every 4 hours */
  },
  whitelist: ['en', 'fr', 'nl', 'base'],
  fallbackLng: 'en',
  debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false,
  },
  ns: namespaces,
  react: {
    wait: true,
  },
  detection: {
    caches,
    cookieMinutes: 160,
    lookupFromPathIndex: 0,
  },
});

export const getLanguage = () => i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en';

export default i18n;
